<template>
	<div id="doctorinfo">
		<div class="top">
			<div class="title">医生详情 <i></i> </div>
			<!-- <div class="tabs">
				<template v-for="(item,i) in list">
					<p :class="item.class == curNow ? 'active':''" :key="i" @click="tabsChange(item)">
						{{item.name}}
						<i></i>
					</p>
					<el-divider direction="vertical" :key="i" v-if="i<list.length-1"></el-divider>
				</template>
			</div> -->
		</div>
		<div label="医生详情" v-if="curNow == '1'">
			<div class="content" v-if="docInfo">
				<p>
					<span>姓名：</span>
					<b>
						{{docInfo.fullName?docInfo.fullName:"待完善"}}
					</b>
				</p>
				<p>
					<span>手机号：</span>
					<b>
						{{docInfo.phoneNumber?docInfo.phoneNumber:"待完善"}}
					</b>
				</p>
				<p>
					<span>职称：</span>
					<b>
						{{docInfo.title?docInfo.title:"待完善"}}
					</b>
				</p>
				<p>
					<span>所属医院：</span>
					<b>
						{{docInfo.hospital?docInfo.hospital:"待完善"}}
					</b>
				</p>
				<p>
					<span>所属科室：</span>
					<b>
						{{docInfo.department?docInfo.department:"待完善"}}
					</b>
				</p>
				<p>
					<span>身份证号：</span>
					<b>
						{{docInfo.cardId?docInfo.cardId:"待完善"}}
					</b>
				</p>
				<p>
					<span>银行卡号：</span>
					<b>
						{{docInfo.bankNumber?docInfo.bankNumber:"待完善"}}
					</b>
				</p>
				<p>
					<span>开户行：</span>
					<b>
						{{docInfo.bankName?docInfo.bankName:"待完善"}}
					</b>
				</p>
			</div>

		</div>
		<div label="参与项目" v-if="curNow == '2'">
			<div class="project">
				<div class="item" v-for="item in objectList" :key="item.id" @click.stop="toMeetList(item)">
					<div class="tags">
						<div class="type" v-if="item.projectType == 'train'">
							<img src="../../../assets/imgs/video.png">患教会
						</div>
						<div class="type" v-if="item.projectType == 'seminar'">
							<img src="../../../assets/imgs/video2.png">研讨会
						</div>
						<div class="idNum">编号：{{item.id}}</div>
					</div>
					<div class="main">
						<div class="left">
							<div class="tit">项目主题：{{item.projectName}}</div>
							<div class="time">
								项目时间：
								{{$moment(item.projectStartDate).format('L') }}
								-
								{{$moment(item.projectEndDate).format('L') }}
							</div>
							<div class="type">
								<span class="WAIT" v-if="item.projectStatus == 'UNDERWAY'">进行中</span>
								<span class="FINISH" v-if="item.projectStatus == 'TIMEOUT'">已结束</span>
							</div>
						</div>
						<div class="opts">
							<el-button class="details" @click.stop="toMeetList(item)">项目详情</el-button>
							<!-- <el-button class="agreement" @click.stop="newMeeting(item)">创建会议</el-button> -->
						</div>
					</div>
				</div>

				<div class="null" v-if="!objectList[0]">
					<img src="../../../assets/imgs/EmptyState.png" alt="">
					<p>还没有参加过项目</p>
				</div>

				<el-pagination background layout="prev, pager, next" :total="total" :page-size="size"
					:current-page.sync="page" class="pagination" @current-change="getdoctorProjectList"
					:hide-on-single-page="false"></el-pagination>
			</div>
		</div>
		<!-- </el-tabs> -->

		<div class="opts">
			<!-- <el-button @click="back()">返回</el-button> -->
			<el-button v-if="curNow == '1' && docInfo.status == 'SUCCESS'" type="primary"
				@click="changeinfo()">修改医生信息</el-button>
			<el-button v-if="docInfo.status == 'INFORMATION'" type="primary"
				@click="auditOperation(true)">审核通过</el-button>
			<el-button v-if="docInfo.status == 'INFORMATION'" type="danger"
				@click="auditOperation(false)">驳回注册</el-button>
		</div>
	</div>
</template>

<script>
	import {
		getDoctorById,
		doctorProjectList,
		examineUser
	} from '@/request/api.js';
	export default {
		name: 'doctorinfo',
		data() {
			return {
				curNow: "1",
				list: [{
						name: '医生详情',
						class: "1"
					},
					{
						name: '参与项目',
						class: "2"
					}
				],
				doctorId: '',
				docInfo: '',
				objectList: "",
				page: 1,
				size: 10,
				total: 0
			}
		},
		created() {
			this.doctorId = this.$route.query.dId;
			this.getDoctorByIdFun()
			this.getdoctorProjectList();
		},
		mounted() {},
		methods: {

			auditOperation(flag) {
				let data = {
					id: this.doctorId
				};
				if (flag) {
					// console.log('审核通过')
					data.status = 'SUCCESS';
					this.examineUserFun(data);
				} else {
					this.$prompt('请输入驳回理由', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						inputPattern: /^.+$/,
						inputErrorMessage: '请输入驳回理由',
						center: true
					}).then(({
						value
					}) => {
						this.confirm(value)
					})
				}
			},

			confirm(value) {
				let data = {
					id: this.doctorId,
					status: 'ERROR',
					errorMessage: value
				};
				this.examineUserFun(data);
			},

			examineUserFun(data) {
				examineUser(data)
					.then(res => {
						if (res.code == 200) {
							if (data.status == 'ERROR') {
								this.$notify({
									title: '提示',
									type: 'success',
									message: '注册请求已驳回',
									position: 'bottom-right'
								});
							} else {
								this.$notify({
									title: '提示',
									type: 'success',
									message: '审核通过',
									position: 'bottom-right'
								});
							};
							this.$router.push({
								name: 'doctor'
							});
						}
					})
			},

			tabsChange(item) {
				this.curNow = item.class;
			},

			// 查看会议详情
			toMeetList(item) {
				this.$router.push({
					name: 'meetinglist',
					query: {
						pId: item.id
					}
				})
			},

			getdoctorProjectList() {
				let data = {
					doctorId: this.doctorId,
					current: this.page,
					size: this.size
				};
				doctorProjectList(data).then(res => {
					if (res.code == 200) {
						this.objectList = res.data.records;
						this.total = res.data.total
					}
				})
			},

			// 修改信息
			changeinfo() {
				this.$router.push({
					name: 'changeInfo',
					query: {
						dId: this.doctorId
					}
				})
			},

			// 返回上一页
			back() {
				this.$router.go(-1);
			},

			getDoctorByIdFun() {
				let data = {
					id: this.doctorId
				};
				getDoctorById(data).then(res => {
					if (res.code == 200) {
						this.docInfo = res.data
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	#doctorinfo {
		padding: 20px;

		.top {
			padding: 20px 20px;
			margin-bottom: 20px;
			border-radius: 20px;
			box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);
		}

		.title {
			font-size: 20px;
			padding: 0 20px 0 30px;
			position: relative;

			i {
				top: 50%;
				left: 0px;
				width: 20px;
				height: 20px;
				position: absolute;
				border-radius: 50%;
				background-color: #3563E9;
				border: 5px solid #C2D0F8;
				transform: translateY(-50%);
			}
		}

		.tabs {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;

			.el-divider {
				width: 2px;
				height: 30px;
				margin: 0;
			}

			p {
				width: 100%;
				padding: 20px 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				cursor: pointer;
				line-height: 2.5;
				position: relative;

				i {
					width: 100%;
					left: 0;
					bottom: 0;
					height: 0;
					background-color: #0065FF;
					position: absolute;
					transition: all .3s;
				}

				span {
					margin-left: 10px;
					height: 26px;
					width: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					background-color: #007AFF;
					border-radius: 5px;
					color: #fff;
				}
			}

			.active i {
				height: 6px;
			}
		}


		.el-tab-pane {
			padding-top: 20px;
		}

		.content {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 40px;

			p {
				width: calc(50% - 20px);
				margin-right: 20px;
				margin-bottom: 20px;
				font-size: 16px;
				display: flex;
				flex-direction: column;

				span {
					color: #112950;
					font-size: 15px;
					line-height: 1;
					margin-bottom: 10px;
				}

				b {
					background-color: #f5f5f5;
					font-weight: normal;
					padding: 16px 10px;
					border-radius: 10px;
				}

				&:nth-of-type(2n) {
					margin-right: 0;
				}
			}
		}

		.project {
			.item {
				display: flex;
				margin: 0 20px 20px 0;
				flex-direction: column;
				padding: 20px;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				transition: all .3s;
				border-radius: 10px;

				&:hover {
					background-color: #E8EEFC;
				}

				.tags {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 10px;

					.type {
						display: flex;
						align-items: center;
						color: #000;
						font-size: 12px;

						img {
							margin-right: 5px;
							width: 20px;
							height: 15px;
						}
					}

					.idNum {
						font-size: 12px;
						color: #999;
					}
				}



				.main {
					display: flex;
					flex-direction: row;
					justify-content: space-between;

					.left {
						.tit {
							font-size: 16px;
							color: #000;
							margin-bottom: 10px;
							line-height: 1;
						}

						.time {
							font-size: 16px;
							color: #333;
							line-height: 1;
							margin-bottom: 10px;
						}

						.type {
							display: flex;

							.el-button--text {
								padding: 0;
								font-size: 13px;
							}

							.WAIT {
								color: #007AFF;
							}

							.FINISH {
								color: #999;
							}
						}
					}

					.opts {
						display: flex;
						align-items: flex-end;

						.details {
							background-color: #007AFF;
							color: #fff;
							padding: 0 25px;
							height: 28px;
						}

						.agreement {
							background-color: #007AFF;
							// background-color: #D43030;
							color: #fff;
							padding: 0 25px;
							height: 28px;
						}
					}
				}
			}

			.null {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				padding: 100px;

				img {
					width: 200px;
				}

				p {
					font-size: 18px;
					color: #A6A6A6;
				}
			}

			.el-pagination {
				text-align: center;
				margin-bottom: 40px;
			}
		}

		.opts {
			display: flex;
			align-items: center;
			justify-content: center;

			.el-button {
				width: 200px;
				margin: 0 20px;
			}
		}
	}
</style>